.img-gallery-carousel {
  border: 1px solid #eee;
  border-radius: 10px;
}
.img-gallery-carousel > div {
  display: flex;
  min-height: 300px;
}

.img-gallery-carousel{
  background-color: rgba(75, 75, 75, 0.5);
}

.galleryImgDiv{
  width: 100%;
  min-height: min(400px, calc(100vh - 350px));
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  align-content: center;
  text-align: center;
  align-items: center;
} 
.galleryImgDiv img{
  visibility: hidden;
  position: absolute;
}

.bottom-drawer{
  z-index: 4000 !important;
}

.landing-img-grid{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.welcome-tile{
  padding: 30px;
  text-align: center;
  color: #fff;
}
.landing-find-guide-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-menu .MuiDrawer-paper{
  min-height: 100vh;
}
